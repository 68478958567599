<template>
    <div class="section section__supplier">
        <div class="transaction__filter--container d-block bg-light bg-background-2 py-2">
            <b-row class="search__export align-items-center">
                <b-col cols="10">
                    <div class="d-flex pl-2">
                        <h5 class="text-dark mb-0 fw-bold-600">Supplier</h5>
                    </div>
                </b-col>
                <b-col cols="2" class="px-0">
                    <div>
                        <b-button class="py-1 border-8" @click="exportReport">
                            Export Data
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </div>
        <vue-good-table v-if="result.data" max-height="100vh" :columns="columns" :rows="result.data" :fixed-header="false"
            :sort-options="{
                enabled: false
            }" :pagination-options="{
    enabled: false,
}">
            <template slot="table-column" slot-scope="props">
                <span v-if="props.column.field == 'action'" />
            </template>
            <template slot="table-row" slot-scope="props">

                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                    <span>
                        <div class="d-flex">
                            <!-- <b-button
                class="mr-1 px-1 border-0 bg-pink"
                @click="deleteData(props.row.id)"
              >
                <feather-icon
                  size="18"
                  class="text-danger"
                  icon="Trash2Icon"
                />
              </b-button> -->
                            <b-button class="bg-white" @click="goToDetail(props.row.uuid)">
                                Lihat Detail
                            </b-button>
                            <!-- <b-button class="bg-white" @click="editItem(props.row)">
                                Kelola User
                            </b-button> -->
                        </div>
                    </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
        <div v-if="result.data" class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
            style="padding: 0.8rem;">
            <div class="d-flex align-items-center">
                <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
                <span class="text-nowrap ">
                    Tampilkan 1 ke {{ filter.per_page }} dari {{ result.total }} transaksi.
                </span>
            </div>
            <div>
                <b-row class="mx-0 align-items-center">
                    <b-col>
                        <pagination class="mb-0" :data="result" :limit="4" :align="'right'"
                            @pagination-change-page="getData" />
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

import moment from 'moment'
import FileSaver from 'file-saver'

export default {
    components: {
        VueGoodTable,
        BFormSelect,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BButton,
        BRow,
        BCol,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    props: {
        getData: {
            type: Function
        },
        result: {
            type: Object
        },
        detailResult: {
            type: Object
        },
        getDetail: {
            type: Function
        }
    },
    data() {
        return {
            progressValue: 35,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Nama Supplier',
                    field: 'name',
                },
                // {
                //     label: 'Nama Cabang',
                //     field: 'cabang',
                // },
                {
                    label: 'Nama Toko',
                    field: 'merchant.name',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            items: [],
            search: '',
            filter: {
                search: '',
                sort_type: 'desc',
                per_page: 10,
            },
        }
    },
    watch: {
        filter: {
            handler(value) {
                this.$emit('filterBranch', value)
            },
            deep: true,
        },
    },
    methods: {
        goToDetail(uuid) {
            this.getDetail(uuid)
            this.$bvModal.show('modal-supplier')
        },
        async exportReport() {
            // const queryParams = filter
    
            const response = await this.$http.get('/super-admin/supplier/export', {
                responseType: 'blob',
                // params: queryParams,
            })
    
            const dateDownload = new Date()
    
            const timestamp = moment(dateDownload).format('YYYYMMDD')
            let fileName = ''
    
            fileName = `daftar-pemasok-${timestamp}.xlsx`
            FileSaver.saveAs(response.data, fileName)
            // console.log(response, ' report')
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.section__supplier {
    .vgt-responsive {
        height: calc(100vh - 235px) !important;
        background-color: #fff;
    }
}
</style>